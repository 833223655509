import ApiService from '@/shared/services/api.service';

export class InternalDocumentsService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/staff-applications', params)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/staff-applications?echo=true', { ...data } )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${id}`, { ...data })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static find(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/staff-applications/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static upload(token, files) {
        return new Promise((resolve, reject) => {
           ApiService.post('/files?echo=true', files, {
               'content-type': 'multipart/form-data',
               'x-file-access-token': token,
           })
               .then((response) => resolve(response.data))
               .catch((error) => reject(error));
        });
    }

    static getActivities(id) {
        return new Promise((resolve, reject) => {
            ApiService.query(`user-messages/identification/${id}`, {
                params: {
                    sort: '-created_date',
                    limit: 100
                }
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => reject(error));
        });
    }

    static findUserActions(params){
        return new Promise((resolve, reject) => {
            ApiService.query('/user-actions', { params })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static assignStaff(params) {
        const { id, payload } = params;
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${id}/assign`, payload)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static approveDocument(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/approve`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static rejectDocument(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/reject`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static signDocument(id, action) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/${action}/sign`, {})
                .then((response) => {
                    if (response.data.success) {
                        resolve(response.data);
                    } else {
                        reject(response.data);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    static validateDocumentSign(payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${payload.id}/confirm-signing`, {
                pin: payload.pin,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static checkSigningStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/staff-applications/${id}/check-signing-status`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static addComments(params) {
        const { id, payload } = params;
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/${id}/comment`, payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static returnDocument(params) {
        const { id, payload } = params;
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${id}/return-document`, payload)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static sendReview(id, comment) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${id}/review`, { comment })
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => reject(error));
        })
    }

    static previewDocument(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/staff-applications/${id}/preview-document`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static generateFinalDocument(id, action) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/${action}/generate-document`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static checkStaffBulkSigningStatus(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/check-bulk-signing-status`, { application_ids: payload})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static validateBulkDocumentSign(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/confirm-bulk-signing`, {
                pin: payload.pin,
                request_id: payload.ids
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static signDocumentBulk(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/bulk-sign`, { application_ids: payload })
                .then(({data}) => resolve(data))
                .catch((error) => reject(error));
        });
    }

    static getRelatedDocuments(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/staff-applications', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}
