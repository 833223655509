import { InternalDocumentsService } from '@/modules/internal-documents/internal-documents-service';
import Errors from '@/shared/error/errors';
import { FormsService } from "@/modules/forms/forms-service";
import { ApplicationTypeService } from "@/modules/application-type/application-type-service";
import Vue from "vue";
import i18n from "@/shared/plugins/vue-i18n";
import { ENROLLMENT_ERRORS } from "@/modules/applications/utils/constants";
import { CommonService } from "@/shared/services/common.service";

// action types
export const DO_CREATE = "create";
export const DO_UPDATE = "update";
export const DO_FIND = "find";
export const DO_FIND_FORM = "findForm";
export const DO_FIND_INTERNAL_DOCUMENT_TYPE = "findInternalDocumentType";
export const DO_UPLOAD = "upload";
export const DO_INIT_STATE = "initFormState";
export const DO_FILTER_DOCS = "filterDocs";
export const DO_FIND_USER_ACTIONS = "findUserActions";
export const DO_RESET_USER_ACTIONS = "doResetUserActions";
export const DO_ASSIGN_STAFF = "assignStaff";
export const DO_APPROVE_DOCUMENT = "approveDocument";
export const DO_REJECT_DOCUMENT = "rejectDocument";
export const DO_SIGN_DOCUMENT = "signDocument";
export const DO_VALIDATE_DOCUMENT_SIGN = "validateDocumentSign";
export const CHECK_SIGNING_STATUS = "checkSigningStatus";
export const DO_ADD_COMMENTS = "addComments";
export const DO_RETURN_DOCUMENT = "returnDocument";
export const DO_SEND_REVIEW = "sendReview";
export const DO_PREVIEW_DOCUMENT = "previewDocument";
export const DO_FIND_APPLICATIONS = "findApplications";
export const DO_FIND_APPLICATION = "findApplication";
export const DO_UPDATE_APPLICATION = "updateApplication";
export const DO_GENERATE_DOCUMENT = "generateFinalDocument";
export const CHECK_STAFF_BULK_SIGNING_STATUS = "checkStaffBulkSigningStatus";
export const DO_VALIDATE_BULK_DOCUMENT_SIGN = "validateBulkDocumentSign";
export const DO_SIGN_DOCUMENT_BULK = "signDocumentBulk";
export const DO_GET_RELATED_DOCUMENTS_BY_IDS = "getRelatedDocumentsByIds";

// mutation types
export const FIND_SUCCESS = "findSuccess";
export const FIND_DOCS_SUCCESS = "fetchSuccess";
export const SET_FILTERED_DOCS = "setFilteredDocs";
export const RESET_FILTERED_DOCS = "resetFilteredDocs";
export const RESET = "reset";
export const SET_UPLOAD_TOKEN = "setUploadToken";
export const SET_FILE_TOKEN = "setFileToken";
export const INIT_STATE = "initState";
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const SET_USER_ACTIONS = 'setUserActions';
export const RESET_USER_ACTIONS = "resetUserActions";
export const DO_UPDATE_RECORD = "updateRecord";
export const SET_TENANT_ID = "setTenantId";
export const SET_AVAILABLE_UATS = "setAvailableUats";
export const SET_USER_ACTIONS_LOADING = "setUserActionsLoading";

const getDefaultState = () => {
    return {
        record: null,
        uploadToken: null,
        fileToken: null,
        docs: [],
        filteredDocs: [],
        totalDocs: 0,
        totalFilteredDocs: 0,
        storeFilters: {},
        currentPage: 1,
        userActions: [],
        availableUatIds: [],
        userActionsLoading: false
    };
};

const state = getDefaultState();

const getters = {
    record: (state) => state.record,
    uploadAccessToken: (state) => state.uploadToken,
    fileToken: (state) => state.fileToken,
    totalDocs: (state) => state.totalDocs,
    docs: (state) => state.docs,
    filteredDocs: (state) => state.filteredDocs,
    totalFilteredDocs: (state) => state.totalFilteredDocs,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    userActions: (state) => state.userActions,
    availableUatIds: (state) => state.availableUatIds,
    userActionsLoading: (state) => state.userActionsLoading
};

const actions = {
    [DO_CREATE](context, values) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "internalDocument/create", { root: true});
            InternalDocumentsService.create(values)
                .then(({ data, headers }) => {
                    const uploadAccessToken = headers["x-file-upload-access-token"];

                    context.commit(SET_UPLOAD_TOKEN, uploadAccessToken);

                    context.commit(FIND_SUCCESS, data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "internalDocument/create", { root: true });
                });
        });
    },
    [DO_UPDATE](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "internalDocuments/update", { root: true });

            InternalDocumentsService.update(id, values)
                .then((response) => {
                    if (response.headers["x-file-download-access-token"]) {
                        context.commit(SET_FILE_TOKEN, response.headers["x-file-download-access-token"]);

                        context.commit(FIND_DOCS_SUCCESS, {
                            docs: response.data?.files || [],
                            totalDocs: response.data?.files?.length || 0,
                        });
                    }

                    if (response.headers["x-file-upload-access-token"]) {
                        context.commit(SET_UPLOAD_TOKEN, response.headers["x-file-upload-access-token"]);
                    }

                    context.commit(FIND_SUCCESS, response.data);

                    resolve(response.data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "internalDocuments/update", { root: true });
                });
        });
    },
    [DO_FIND](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "internalDocument/find", { root: true });

            InternalDocumentsService.find(id)
                .then((data) => {
                    if (data.headers["x-file-download-access-token"]) {
                        context.commit(SET_FILE_TOKEN, data.headers["x-file-download-access-token"]);

                        context.commit(FIND_DOCS_SUCCESS, {
                            docs: data.data?.files || [],
                            totalDocs: data.data?.files?.length,
                        });
                    }

                    if (data.headers["x-file-upload-access-token"]) {
                        context.commit(SET_UPLOAD_TOKEN, data.headers["x-file-upload-access-token"]);
                    }

                    context.commit(FIND_SUCCESS, data.data);
                    resolve(data.data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "internalDocument/find", { root: true });
                });
        });
    },
    [DO_FIND_FORM](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "internalDocument/findForm", { root: true });
            FormsService.find(id).then(({ data }) => {
                resolve(data);
            }).catch((error) => {
                Errors.handle(error);
                reject();
            }).finally(() => {
                context.commit("shared/deactivateLoading", "internalDocument/findForm", { root: true });
            });
        });
    },
    [DO_FIND_INTERNAL_DOCUMENT_TYPE](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "internalDocument/findInternalDocumentType", { root: true });
            ApplicationTypeService.find(id)
                .then(async ({ data }) => {
                    const { tenant_id } = data;
                    context.commit(SET_TENANT_ID, tenant_id);

                    const uats = await CommonService.jurisdictionTerritorialAdministrativeUnitsListAutocomplete({ parent_id: tenant_id })
                    context.commit(SET_AVAILABLE_UATS, uats);
                    
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "internalDocument/findInternalDocumentType", { root: true });
                });
        });
    },
    [DO_UPLOAD](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "control/upload", { root: true });
            const { uploadAccessToken, files } = payload;
            InternalDocumentsService.upload(uploadAccessToken, files)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject(error);
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "control/upload", { root: true });
                });
        });
    },
    [DO_INIT_STATE](context) {
        context.commit(INIT_STATE);
    },
    [DO_FILTER_DOCS](context, params) {
        const docs = context.getters.docs;
        const { filterFn } = params;
        if (filterFn) {
            const filteredDocs = docs.filter(filterFn);
            context.commit(SET_FILTERED_DOCS, {
                docs: filteredDocs,
                totalDocs: docs?.length,
            });
        } else {
            context.commit(RESET_FILTERED_DOCS);
        }
    },
    [DO_FIND_USER_ACTIONS](context, params) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading","internalDocuments/findUserActions",{ root: true });
            InternalDocumentsService.findUserActions(params)
                .then((data) => {
                    resolve(data);
                    context.commit(SET_USER_ACTIONS, data);

                    if (params.query.includes('PROCESSING')) {
                        context.commit(SET_USER_ACTIONS_LOADING, data.length == 0)
                    }
                    
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading","internalDocuments/findUserActions",{ root: true });
                });
        });
    },
    [DO_RESET_USER_ACTIONS](context) {
        context.commit(RESET_USER_ACTIONS);
    },
    [DO_ASSIGN_STAFF](context, params) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading","internalDocuments/assignStaff",{ root: true });
            InternalDocumentsService.assignStaff(params)
                .then((response) => {
                    context.commit(FIND_SUCCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading","internalDocuments/assignStaff",{ root: true });
                });
        });
    },
    [DO_APPROVE_DOCUMENT](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "internalDocuments/approveDocument",{ root: true });
            InternalDocumentsService.approveDocument(id)
                .then(() => {
                    context.commit(DO_UPDATE_RECORD, { status: "approved" });
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading","internalDocuments/approveDocument",{ root: true });
                });
        });
    },
    [DO_REJECT_DOCUMENT](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "internalDocuments/rejectDocument", { root: true });
            InternalDocumentsService.rejectDocument(id)
                .then((data) => {
                    context.commit(FIND_SUCCESS, data);
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading","internalDocuments/rejectDocument",{ root: true });
                });
        });
    },
    [DO_SIGN_DOCUMENT](context, { id, action }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading","internalDocuments/signDocument",{ root: true });
            InternalDocumentsService.signDocument(id, action)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    const { message } = error?.response?.data ?? "";
                    if (ENROLLMENT_ERRORS.includes(message)) {
                        Vue.alert({
                            title: i18n.t("INTERNAL_DOCUMENTS.NOTIFIERS.INVALID_STAFF_ENROLLMENT_TITLE"),
                            type: "warning",
                            isTextHtml: true,
                            text: `<p>${i18n.t("INTERNAL_DOCUMENTS.NOTIFIERS.INVALID_STAFF_ENROLLMENT_TEXT")}</p>`,
                            confirmButtonText: i18n.t("INTERNAL_DOCUMENTS.NOTIFIERS.INVALID_STAFF_ENROLLMENT_BUTTON_TEXT"),
                            confirmCallback: () => {
                                const router = routerAsync();
                                router.push({ name: "profile" });
                            },
                        });
                    } else {
                        Errors.handle(error);
                    }
                    reject(error);
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading","internalDocuments/signDocument",{ root: true }
                    );
                });
        });
    },
    [DO_VALIDATE_DOCUMENT_SIGN](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading","internalDocuments/validateSignDocument",{ root: true });
            InternalDocumentsService.validateDocumentSign(payload)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading","applications/validateSignDocument",{ root: true });
                });
        });
    },
    async [CHECK_SIGNING_STATUS](context, id) {
        context.commit("shared/activateLoading","internalDocuments/checkStaffSigningStatus",{ root: true });
        try {
            const data = await InternalDocumentsService.checkSigningStatus(id);
            return data;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit("shared/deactivateLoading","internalDocuments/checkStaffSigningStatus",{ root: true });
        }
    },
    [DO_ADD_COMMENTS](context, params) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading","internalDocuments/returnDocument",{ root: true });
            InternalDocumentsService.addComments(params)
                .then((response) => {
                    context.commit(FIND_SUCCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading","internalDocuments/returnDocument",{ root: true });
                });
        });
    },
    [DO_RETURN_DOCUMENT](context, params) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading","internalDocuments/returnDocument",{ root: true });
            InternalDocumentsService.returnDocument(params)
                .then((response) => {
                    context.commit(FIND_SUCCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading","internalDocuments/returnDocument",{ root: true });
                });
        });
    },
    [DO_SEND_REVIEW](context, payload) {
        return new Promise((resolve) => {
            context.commit("shared/activateLoading","internalDocuments/sendReview",{ root: true });
            InternalDocumentsService.sendReview(payload.id, payload.comment)
                .then((data) => {
                    context.commit(DO_UPDATE_RECORD, data);
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading","internalDocuments/sendReview",{ root: true });
                });
        });
    },
    [DO_PREVIEW_DOCUMENT](context, { id }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading","internalDocuments/previewDocument",{ root: true });
            InternalDocumentsService.previewDocument(id)
                .then(({ headers, data }) => {
                    const downloadAccessToken = headers["x-file-download-access-token"];
                    resolve({ data, downloadAccessToken });
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading","internalDocuments/previewDocument",{ root: true });
                });
        });
    },
    [DO_FIND_APPLICATIONS](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "internalDocuments/findApplications", { root: true });
            InternalDocumentsService.list(payload)
                .then(({data}) => resolve(data))
                .catch((error) => {
                    Errors.handle(error);
                    reject(error);
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "internalDocuments/findApplications", { root: true });
                });
        })
    },
    [DO_FIND_APPLICATION](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "internalDocuments/findApplication", { root: true });
            InternalDocumentsService.find(id)
                .then(({data}) => resolve(data))
                .catch((error) => {
                    Errors.handle(error);
                    reject(error);
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "internalDocuments/findApplication", { root: true });
                });
        })
    },
    [DO_UPDATE_APPLICATION](context, { id, values, update }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "internalDocuments/updateApplication", { root: true });

            InternalDocumentsService.update(id, values)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "internalDocuments/updateApplication", { root: true });
                });
        });
    },
    [DO_GENERATE_DOCUMENT](context, { id, action }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading","internalDocuments/generateDocument",{ root: true });
            InternalDocumentsService.generateFinalDocument(id, action)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading","internalDocuments/generateDocument",{ root: true });
                });
        });
    },
    async [CHECK_STAFF_BULK_SIGNING_STATUS](context, ids) {
        context.commit("shared/activateLoading", "internalDocuments/checkStaffBulkSigningStatus", { root: true });
        try {
            const data = await InternalDocumentsService.checkStaffBulkSigningStatus(ids);
            return data;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit("shared/deactivateLoading", "internalDocuments/checkStaffBulkSigningStatus", { root: true });
        }
    },
    [DO_VALIDATE_BULK_DOCUMENT_SIGN](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "internalDocuments/validateBulkSignDocument", { root: true });
            InternalDocumentsService.validateBulkDocumentSign(payload)
                .then((data) => {
                    context.commit("internalDocuments/list/setSelectedRows", [], { root: true });
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "internalDocuments/validateBulkSignDocument", { root: true });
                });
        });
    },
    [DO_SIGN_DOCUMENT_BULK](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "internalDocuments/signDocumentBulk", { root: true });
            InternalDocumentsService.signDocumentBulk(payload)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    const { message } = error?.response?.data ?? "";
                    if (ENROLLMENT_ERRORS.includes(message)) {
                        Vue.alert({
                            title: i18n.t("INTERNAL_DOCUMENTS.NOTIFIERS.INVALID_STAFF_ENROLLMENT_TITLE"),
                            type: "warning",
                            isTextHtml: true,
                            text: `<p>${i18n.t("INTERNAL_DOCUMENTS.NOTIFIERS.INVALID_STAFF_ENROLLMENT_TEXT")}</p>`,
                            confirmButtonText: i18n.t("INTERNAL_DOCUMENT.NOTIFIERS.INVALID_STAFF_ENROLLMENT_BUTTON_TEXT"),
                            confirmCallback: () => {
                                const router = routerAsync();
                                router.push({ name: "profile" });
                            },
                        });
                    } else {
                        Errors.handle(error);
                        reject();
                    }
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "internalDocuments/signDocumentBulk", { root: true });
                });
        });
    },
    async [DO_GET_RELATED_DOCUMENTS_BY_IDS](context, applicationIds) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/getRelatedDocuments", {
                root: true,
            });
            let query = `_id in ['${applicationIds}']`;
    
            const params = {
                limit: 100,
                sort: '-issued_date',
                query,
                fields: '_id,status',
            };
    
            InternalDocumentsService.getRelatedDocuments({ params })
                .then((data) => {
                    resolve(data.data ?? []);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/getRelatedDocuments",
                        { root: true }
                    );
                });
        });
    },
}

const mutations = {
    [FIND_SUCCESS](state, data) {
        state.record = data;
    },
    [FIND_DOCS_SUCCESS](state, payload) {
        state.docs = payload.docs;
        state.totalDocs = payload.totalDocs;
        state.filteredDocs = payload.docs;
        state.totalFilteredDocs = payload.totalDocs;
    },
    [SET_FILTERED_DOCS](state, payload) {
        state.filteredDocs = payload.docs;
        state.totalFilteredDocs = payload.totalDocs;
    },
    [RESET_FILTERED_DOCS](state) {
        state.filteredDocs = state.docs;
        state.totalFilteredDocs = state.totalDocs;
    },
    [RESET](state) {
        state.record = null;
    },
    [SET_UPLOAD_TOKEN](state, token) {
        state.uploadToken = token;
    },
    [SET_FILE_TOKEN](state, token) {
        state.fileToken = token;
    },
    [INIT_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [SET_USER_ACTIONS](state, payload) {
        var merge = (firstArray, secondArray, prop) =>
            firstArray.filter((el) => !secondArray.find((elem) => el[prop] === elem[prop])).concat(secondArray);
        state.userActions = merge(state.userActions, payload, "id");
    },
    [RESET_USER_ACTIONS](state) {
        state.userActions = [];
    },
    [DO_UPDATE_RECORD](state, payload) {
        state.record = { ...state.record, ...payload };
    },
    [SET_TENANT_ID](state, data) {
        state.tenantId = data;
    },
    [SET_AVAILABLE_UATS](state, data) {
        const ids = data.map(target => target.id);
        state.availableUatIds = ids;
    },
    [SET_USER_ACTIONS_LOADING](state, data) {
        state.userActionsLoading = data;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
